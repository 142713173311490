import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_YouTube = _resolveComponent("YouTube")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_YouTube, {
      src: "https://www.youtube.com/watch?v=FTA0jyo_GoY&list=RDu3ePPA0yzSU&index=11",
      ref: "youtubeRef"
    }, null, 512)
  ]))
}