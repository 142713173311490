
import { defineComponent, onMounted, ref } from "vue";
import YouTube from "vue3-youtube";

export default defineComponent({
  name: "youtube-player",
  components: { YouTube },
  setup() {
    const youtubeRef = ref();

    onMounted(() => {
      // Access the DOM element using the ref and call the playVideo method
      youtubeRef.value.playVideo;
    });

    return { youtubeRef };
  },
});
